import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import PaymentLogo from "../../../V2/Banners/PaymentLogo"

const StoryblokPaymentLogoBanner = ({
  blok,
}: Storyblok.BlokProps<Storyblok.PaymentLogoBanner>) => {
  return (
    <PaymentLogo
      backgroundColor={blok.backgroundColor}
      imageUrl={blok.image.filename}
      imageAlt={blok.image.alt}
      rounded={blok.rounded}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}

export default StoryblokPaymentLogoBanner
