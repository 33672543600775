import classNames from "classnames"
import React from "react"

import GatsbyStoryblokImage from "../../elements/V2/GatsbyStoryblokImage"
import Section from "../../elements/V2/Section"

import { getBackgroundColorClass } from "@utils/V2/color"
import { Color } from "constants/V2/color"

type Props = {
  backgroundColor: Color
  imageUrl: string
  imageAlt: string
  rounded?: boolean
}

const PaymentLogo = ({
  backgroundColor,
  imageUrl,
  imageAlt,
  rounded = true,
  ...props
}: Props) => {
  return (
    <Section
      className={classNames(
        getBackgroundColorClass(backgroundColor),
        "pb-lg -mb-lg relative flex items-center justify-center overflow-hidden",
        {
          "rounded-t-xl -mt-xl": rounded,
        }
      )}
      {...props}
    >
      <GatsbyStoryblokImage
        aspectRatio="fullWidth"
        loadType="eager"
        image={imageUrl}
        alt={imageAlt || "Payment logo"}
        className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"
      />
    </Section>
  )
}

export default PaymentLogo
